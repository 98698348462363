.search-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4vh;
  }
  
  .search-form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .search-input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 10px;
  }
  
  .search-button {
    
    
    border: 1px solid #ccc;
    background-color: #f1f1f1;
    cursor: pointer;
  }
  
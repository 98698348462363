.technqiue-table {
    min-height: 80vh;
    overflow-x: auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    align-content: flex-start;
}

th,td {
    padding:10px;
    font-size:0.8em;
    font-weight: bold;
}

#spacing {
    border-collapse:separate;
    border-spacing:0 13px;
}

.glow {
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
}

.vector-col {
    padding: 0px 8px;
    text-align: center;
    width: 20ch;
    word-wrap: break-word;
}

.vector-title {
    color: inherit;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    height: 3.25em;
    vertical-align: text-bottom;
    font-size: 1.1rem;
    font-weight: 400;
    border-bottom: 0.2rem;
    border-bottom-style: double;
}

.vector-title:hover {
    color: white;
}

.vector-subtitle {
    font-size: 0.8rem;
    margin: 10px 0px;
    cursor: pointer;
}

.technqiue-table table {
    table-layout:fixed;
    width:100%;
    font-size: 0.8rem;
}

.technqiue-table table tr td {
    border: 0.05rem solid;
    /* outline-color: ivory; */
}

.technqiue-table table tr td > .technique-box {
    width: 100%;
    height: 100%;
    padding: 4px;
    cursor: pointer;
    color: inherit;
    white-space: nowrap; 
    text-overflow: ellipsis; 
    overflow: hidden;
    display: block; 
}

.technqiue-table table tr td > .technique-box:hover {
    color: white;
}

.technique-box > .bullet-counter {
    font-size: 0.7rem;
}

#unclickable {
    display: inline-block;
    pointer-events: none;
}

.popup {
    position: absolute; /* changed from fixed */
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 10%;
    height: 10%;
    transform: translateX(-50%);
}


.popup-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
}

.popup-close-btn:focus {
    outline: none;
}

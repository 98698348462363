

.payload-content {
    font-family: monospace; /* Use a monospaced font for better readability */
    font-size: 0.9rem; /* Adjust the font size as needed */
    white-space: pre-wrap; /* Ensure long lines wrap */
    word-wrap: break-word; /* Break words to prevent horizontal scrolling */
    max-height: 75vh; /* Set a maximum height */
    overflow-y: auto; /* Enable vertical scrolling */
    color: white; /* Text color */
}

.title-test{
    word-break: break-all;
}

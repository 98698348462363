.bullet-document {
    height: 75vh;
}

.bullet-document .main-title {
    font-weight: lighter;
    border-bottom: 2px;
    border-bottom-style: solid;
    margin-bottom: 1rem;
}

.bullet-document .subtitle {
    font-weight: lighter;
    margin-left: 0.5rem;
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
    text-decoration: underline;

}

.description-document .main-title,
.description-document h1 > i {
    color: #fc4b6c;
}

.description-document .subtitle {
    text-decoration-color: #fc4b6c;
}

.intel-document .main-title,
.intel-document h1 > i {
    color: #26c6da;
}

.intel-document .subtitle {
    text-decoration-color: #26c6da;
}

.intel-document hr {
    border-top: 1px solid #26c6da;
}

.instructions-document .main-title,
.instructions-document h1 > i {
    color: #ffc107;
}

.instructions-document .subtitle {
    text-decoration-color: #ffc107;
}

.bullet-document ol,
.bullet-document ul {
    padding-left: 2rem;
}

.bullet-document p {
    padding-left: 1rem;
    font-size: 1rem;
}

.bullet-document li {
    font-size: 1rem;
}

.bullet-document img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
}
.note-modal {
    max-width: 600px; /* Adjust the size of the modal to your preference */
  }
  
  .note-modal-header {
    background-color: #F4F85F; /* Yellow color for the header to match the note */
    border-bottom: none; /* Remove the default border */
  }
  
  .note-modal-body {
    background: transparent; /* Transparent background for the modal body */
    padding: 0; /* Remove default padding */
  }
  
  .note-paper {
    background-color: #F4F85F; /* Yellow background color */
    padding: 20px; /* Add some padding */
    border-radius: 6px; /* Rounded corners for the paper */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a lifted paper effect */
    font-family: 'Courier New', Courier, monospace; /* Monospaced font for a typewritten look */
    min-height: 200px; /* Minimum height */
    white-space: pre-wrap; /* Preserve whitespaces and line breaks */
    border: 1px dashed #ccc; /* Optional: Add a dashed border */
    line-height: 2em;
  }
  
  .note-line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Creates the line */
    padding: 0; /* Adjust as needed */
  }

  .note-text {
    margin: 0; /* Reset margin for the paragraph */
    color: #000000;
    font-weight: bold;
    line-height: 1.6em; /* Adjust line height to match the lines */
    background-size: 100% 1.6em; /* Size of the lines */
    font-size: medium;
  }
  
/* BlueRuleMenu.css */
.flex-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
}

.left-panel {
    flex: 1;
    overflow-y: auto;
    border-right: 1px solid #ddd;
    background-color: #f5f5f5;
    padding: 20px;
}

.right-panel {
    flex: 2;
    overflow-y: auto;
    background-color: #424953;
    padding: 20px;
}

.loading-container {
    text-align: center;
    font-size: 24px;
    color: white;
}

.larger-table-container {
    max-width: 1200px; /* or any width you prefer */
}

/* Define classes for each status color */
.status-pass {
    color: green;
  }
  
  .status-fail {
    color: red;
  }
  
  .status-no-defense {
    color: orange;
  }
  
  .status-detected {
    color: blue;
  }
  
  .status-no-poc {
    color: purple;
  }
  
  .status-no-working {
    color: brown;
  }

  .status-declined{
    color:darksalmon;
  }
  
  .status-select {
    color: inherit;
  }
  
.filter-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;
}

.button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.bulletCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    box-shadow: 4px 8px white;
}

.bulletCard * {
    font-family: 'Inris Sans', sans-serif;
}

.circle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.line {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    border-bottom: 2px solid #727B89;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.linelight {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #727B89;
    box-sizing: border-box;
}

.tag-circle {
    padding: 0px 10px;
    background-color: #D9D9D9;
    color: black;
    border-radius: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
}

.irrelevant-circle {
    padding: 0px 10px;
    background-color: #ff1f1f;
    color: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
}

.fade-out {
    position: relative;
    overflow: hidden;
    transition: height 0.5s ease, max-height 0.5s ease;
}

.fade-out::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.5em;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #424953);
    pointer-events: none;
}


.buttonsContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
}


.custom-link-button {
    color: white;
    text-decoration: none;
}

.custom-link-button:hover {
    color: white;
    text-decoration: underline;
}

.change-on-hover {
    transition: transform 0.1s ease-in-out;
}

.change-on-hover:hover {
    color: #BFBFBF;
}

.custom-icon {
    width: 36px;
    /* Set the desired width */
    height: 36px;
    /* Set the desired height */
    margin: 0;
    /* Remove any margin */
    padding: 0;
    /* Remove any padding */
    display: flex;
    align-items: center;
    margin-right: 13px;
}

.parent-element {
    color: white;
}

.options-bar {
    margin-bottom: 20px;
    margin-left: 15px;
    margin-right: 18px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    background: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.options-bar .btn {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
}

.options-bar .download-options {
    margin-top: 10px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.options-bar .download-options h5 {
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
}

.options-bar .download-options label {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.options-bar .download-options input[type='checkbox'] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}




.header-image-container {
    width: 25%; /* Adjust the width as needed */
    height: 25%; /* Adjust the height as needed */
    overflow: hidden; /* Hide the overflowing parts of the image */
}

.header-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
}
  
.scenario-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.1s ease, border 0.1s ease; /* optional for smooth transitions */
}

.scenario-card img {
  max-width: 100%;
  height: auto;
}

.scenario-card-title {
  font-weight: bold;
  font-size: 1.25rem;
}

.scenario-card-text {
  margin-bottom: 1rem;
}

/* The equal-height class remains the same */
.equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* 
  Higher specificity: .scenario-card.selected-card 
  Also add a green "glow" effect via box-shadow 
*/
.scenario-card.selected-card {
  border: 3px solid #28a745 !important; /* thicker green border */
  box-shadow: 0 0 10px 2px rgba(40, 167, 69, 0.5); /* green-ish glow */
}
